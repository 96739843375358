"use client";

type ManageConsentButtonProps = {
  readonly children: JSX.Element;
  readonly className?: string;
};
export function ManageConsentButton({
  children,
  className
}: ManageConsentButtonProps) {
  return <button className={className} onClick={() => {
    /* eslint-disable no-undef -- Cookiebot is injected by an external script. */
    if (typeof Cookiebot !== "undefined") {
      Cookiebot.show();
    }
    /* eslint-enable no-undef */
  }} type="button" data-sentry-component="ManageConsentButton" data-sentry-source-file="ManageConsentButton.component.tsx">
      {children}
    </button>;
}